/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Axios from "axios";
import { Link } from "react-router-dom";
import {
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showAlert,
  getReferenceID,
  removePromoCkValue,
  showCustomAlert,
  shorten,
  createScriptForAddToCart,
} from "../Helpers/SettingHelper";
import {
  appId,
  apiUrl,
  apiUrlV2,
  tagImageUrl,
  deliveryId,
  noimage,
} from "../Helpers/Config";
import MenuNavigationTab from "./MenuNavigationTab";
import cartLike from "../../common/images/heart-black.svg";
import cartLikeActive from "../../common/images/heart-bg-red.svg";
import { GET_PRODUCT_LIST, GET_FAVOURITE } from "../../actions";
import proVouchar from "../../common/images/pro-voucher.png";
import cashVouchar from "../../common/images/cash-voucher.png";
import smartPhone from "../../common/images/smart-phone.png";
import { isNull } from "underscore";
var Parser = require("html-react-parser");
var qs = require("qs");
class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      loading: true,
      productCommon: [],
      productDetails: [],
      filter_Tag: [],
      filter_Brand_Tag: "",
      cartItemsLength: 0,
      catSlug: "",
      subcatSlug: "",
      sort_By_Price: "",
      sort_By_PriceName: "Sort By",
      sort_By_category: "",
      sort_By_categoryName: "Shop By",
      showShortByPrice: false,
      showShortByCategory: false,
      searchKeyWord: "",
      emptysearchKey: 0,
      productDetailsHtml: "",
      viewProductSlug: "",
      startProDetails: 0,
      scrollProduct: "",
      favproductID: [],
      favdeleteproductID: [],
      customerId:
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId"),

      voucherProductDetail: [],
      voucher_product_qty: "",
      voucher_name: "",
      voucher_mobile: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
      error_voucher_mobile: "",
      voucherIndexFlag: "",
    };
  }
  componentDidMount() {
    var currentthis = this;
    $(document).click(function (e) {
      if (!$(e.target).is(".filters-lhs, .filters-lhs * ")) {
        currentthis.setState({ showShortByPrice: false });
      }
      if (!$(e.target).is(".filters-rhs, .filters-rhs * ")) {
        currentthis.setState({ showShortByCategory: false });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (this.state.searchKeyWord !== nextProps.productState.searchKeyWord) {
      if (nextProps.productState.searchKeyWord !== "") {
        this.setState(
          { searchKeyWord: nextProps.productState.searchKeyWord },
          function () {
            showLoader("productlist-main-div", "class");
            this.productsubcatlist();
          }
        );
      }
    }
    if (this.state.emptysearchKey !== nextProps.productState.emptysearchKey) {
      this.setState(
        {
          emptysearchKey: nextProps.productState.emptysearchKey,
          searchKeyWord: "",
        },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("emptysearchKey", 0);
          this.setState({ emptysearchKey: 0 });
        }
      );
    }

    if (nextProps.productState.stopFilterPrice === "Yes") {
      this.sortByPrice(nextProps.productState.sort_By_Price);
      this.props.sateValChange("stopFilterPrice", "No");
    }
    if (nextProps.productState.startFilter === "Yes") {
      this.setState(
        {
          showShortByPrice: false,
          showShortByCategory: false,
          sort_By_Price: "",
          sort_By_PriceName: "Sort By",
          sort_By_category: "",
          sort_By_categoryName: "Shop By",
        },
        function () {
          this.props.sateValChange("startFilter", "No");
        }
      );
    }
    if (selectedNavigation !== this.state.selectedNavigation) {
      var windowWidth = $("body").width();
      if (
        nextProps.match.path !== "/menu" &&
        (windowWidth < 1024 || windowWidth > 1220)
      ) {
        $("html, body").animate(
          { scrollTop: $(".productlist-main-div").offset().top },
          1000
        );
      }

      var catSlug = "";
      var subcatSlug = "";
      if (selectedNavigation !== "") {
        showLoader("innerproduct_row", "class");
        catSlug = selectedNavigation;
        subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.getProductList(catSlug, subcatSlug, "");
        $(".addcart_done_maindiv").hide();
        $(".smiple_product_lk").show();
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
        catSlug: catSlug,
        subcatSlug: subcatSlug,
      });
    }
    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }
    if (nextProps.productState.setFiltetBrandTag === "Yes") {
      this.setState(
        { filter_Brand_Tag: nextProps.productState.filterBrandTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetBrandTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (this.state.cartItemsLength !== nextProps.productState.cartItemsLength) {
      this.setState(
        { cartItemsLength: nextProps.productState.cartItemsLength },
        function () {
          this.productsubcatlist();
        }
      );
    }
  }

  productsubcatlist() {
    var subcatlistArr = this.state.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      $("#no_product_error").hide();
      setTimeout(function () {
        if ($(".productlist-main-div.loader-main-cls").length > 0) {
          hideLoader("productlist-main-div", "class");
        }
      }, 1000);
      setInterval(function () {}, 1000);
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          hideLoader("innerproduct_row", "class");
          return (
            <div
              className="innerproduct"
              key={categoryIndex}
              id={"pro_cat_" + categories.pro_subcate_slug}
            >
              <h3 id={categories.pro_subcate_slug} className="sub_cate_title">
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              {categories.pro_subcate_short_description !== "" &&
                categories.pro_subcate_short_description !== null && (
                  <p>
                    {stripslashes(categories.pro_subcate_short_description)}
                  </p>
                )}
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.pro_cate_slug,
                  categories.pro_subcate_slug
                )}
              </ul>
            </div>
          );
        }
      });
      this.setState({ productDetails: productDetails }, function () {
        var totalProducts = 0;
        if ($(".innerproduct").length > 0) {
          $(".innerproduct").map(function () {
            if ($(this).find(".products-list-ulmain li").length > 0) {
              totalProducts++;
              $(this).find(".sub_cate_title").show();
              $(this).find(".sub_cate_description").show();
            } else {
              $(this).find(".sub_cate_title").hide();
              $(this).find(".sub_cate_description").hide();
            }
          });
          if (totalProducts > 0) {
            $("#no_product_error").hide();
          } else {
            $("#no_product_error").show();
          }
        }
        if (this.state.scrollProduct !== "") {
          if ($("#prolistproIndex-" + this.state.scrollProduct).length > 0) {
            $("html, body").animate(
              {
                scrollTop:
                  $("#prolistproIndex-" + this.state.scrollProduct).offset()
                    .top - 100,
              },
              500
            );
            this.setState({ scrollProduct: "" });
          }
        }
      });
      return productDetails;
    } else {
      if (this.state.loading === false) {
        setInterval(function () {
          if ($(".productlist-main-div.loader-main-cls").length > 0) {
            // hideLoader("productlist-main-div", "class");
          }
        }, 3000);
        //$("#no_product_error").show();
      }

      return "";
    }
  }

  chkProStockCls(proSlug, Stock, taginfo) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    var taginfo =
      taginfo !== "" && taginfo !== undefined && taginfo !== null
        ? " offer_tag_show"
        : "";
    if (Stock > 0 && taginfo !== "") {
      returnText = "products-single-li " + proSlug + actClstxt + taginfo;
    } else if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }
    return returnText;
  }

  viewProDetail(productDetail, pro_subcate_slug, event) {
    event.preventDefault();

    if (productDetail.product_stock > 0) {
      this.props.history.push(
        "/menu/" +
          this.state.selectedNavigation +
          "/" +
          pro_subcate_slug +
          "/" +
          productDetail.product_slug
      );
    }
  }

  openProductUrl(pro_cate_slug, pro_subcate_slug, product_slug) {
    var orderOutletId = cookie.load("orderOutletId");

    if (orderOutletId === "" || orderOutletId === undefined) {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return false;
    }

    this.props.history.push(
      "/menu/" + pro_cate_slug + "/" + pro_subcate_slug + "/" + product_slug
    );
  }

  /* Products List */
  productList(list, pro_cate_slug, pro_subcate_slug) {
    var imageSource = this.props.productCommon.product_image_source;
    var tagimagesource = this.props.productCommon.tag_image_source;
    var productgalleryimagesource =
      this.props.productCommon.product_gallery_image_source;
    var totalProduct = 0;
    const listProduct = list.map((productDetail, index) => {
      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      var productName =
        productDetail.product_alias !== ""
          ? stripslashes(productDetail.product_alias)
          : stripslashes(productDetail.product_name);
      let filter = this.checkFilterTag(productDetail.product_tag);
      var existCart = this.checkExistCart(productDetail.product_id);
      let checkSeach = this.checkSeach(
        productName,
        productDetail.product_tag,
        productDetail.product_brand_tag
      );

      if (this.props.productCommon.main_category_slug !== "") {
        this.props.sateValChange(
          "selectedMainCat",
          this.props.productCommon.main_category_slug
        );
        this.props.sateValChange(
          "selectedMainCatId",
          this.props.productCommon.main_category_id
        );
      }

      var cart_label = "Add <span>to cart</span>";

      if (checkSeach === 1) {
        totalProduct++;
        return (
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock,
              productDetail.product_tag_info
            )}
            id={prodivId}
            key={index}
          >
            <div
              className={
                productDetail.product_tag_info !== "" &&
                productDetail.product_tag_info !== null
                  ? "box-outer product-list-main-div"
                  : "product-list-main-div"
              }
            >
              <div id={"prolist" + prodivId} className="product_list_sec">
                {productDetail.product_tag_info !== "" &&
                  productDetail.product_tag_info !== null && (
                    <div className="sale-flag-sash">
                      <span className="sale-text">
                        {productDetail.product_tag_info}
                      </span>
                    </div>
                  )}

                {this.state.customerId !== "" ? (
                  <div>
                    {this.state.favdeleteproductID.length > 0 &&
                    this.state.favdeleteproductID.indexOf(
                      productDetail.product_primary_id
                    ) >= 0 ? (
                      <a
                        href={void 0}
                        className="like"
                        onClick={this.addFavourite.bind(
                          this,
                          productDetail.product_primary_id,
                          "Yes"
                        )}
                      >
                        <img src={cartLike} className="no_mou" />
                        <img
                          src={cartLikeActive}
                          className="active-hart mou_active"
                        />
                      </a>
                    ) : (productDetail.fav_product_primary_id !== null &&
                        productDetail.fav_product_primary_id !== "" &&
                        typeof productDetail.fav_product_primary_id !==
                          undefined &&
                        typeof productDetail.fav_product_primary_id !==
                          "undefined") ||
                      this.state.favproductID.indexOf(
                        productDetail.product_primary_id
                      ) >= 0 ? (
                      <a
                        href={void 0}
                        className="like active"
                        onClick={this.addFavourite.bind(
                          this,
                          productDetail.product_primary_id,
                          "No"
                        )}
                      >
                        <img src={cartLike} className="no_mou" />
                        <img src={cartLikeActive} className="active-hart" />
                      </a>
                    ) : (
                      <a
                        href={void 0}
                        className="like"
                        onClick={this.addFavourite.bind(
                          this,
                          productDetail.product_primary_id,
                          "Yes"
                        )}
                      >
                        <img src={cartLike} className="no_mou" />
                        <img
                          src={cartLikeActive}
                          className="active-hart mou_active"
                        />
                      </a>
                    )}
                  </div>
                ) : (
                  <div>
                    <a
                      href={void 0}
                      className="like"
                      onClick={this.addFavourite.bind(
                        this,
                        productDetail.product_primary_id,
                        "Yes"
                      )}
                    >
                      <img src={cartLike} className="no_mou" />
                      <img
                        src={cartLikeActive}
                        className="active-hart mou_active"
                      />
                    </a>
                  </div>
                )}

                <div className="products-image-div">
                  <a
                    href={void 0}
                    // to={
                    //   "/menu/" +
                    //   this.state.selectedNavigation +
                    //   "/" +
                    //   pro_subcate_slug +
                    //   "/" +
                    //   productDetail.product_slug
                    // }
                    onClick={this.viewProDetail.bind(
                      this,
                      productDetail,
                      pro_subcate_slug
                    )}
                  >
                    {productDetail.product_thumbnail !== "" &&
                    productDetail.product_thumbnail !== null ? (
                      <img
                        src={
                          imageSource + "/" + productDetail.product_thumbnail
                        }
                        alt={
                          productDetail.product_alias !== ""
                            ? stripslashes(productDetail.product_alias)
                            : stripslashes(productDetail.product_name)
                        }
                      />
                    ) : (
                      <img
                        src={noimage}
                        alt={
                          productDetail.product_alias !== ""
                            ? stripslashes(productDetail.product_alias)
                            : stripslashes(productDetail.product_name)
                        }
                      />
                    )}
                    {productDetail.rating_reviews.rating > 0 && (
                      <fieldset className="pantry_rating">
                        <input
                          type="radio"
                          id="star5"
                          name="rating"
                          value="5"
                        />
                        <label
                          className={
                            productDetail.rating_reviews.rating >= 5
                              ? "full custom_checked"
                              : "full"
                          }
                          htmlFor="star5"
                          title="5 stars"
                        ></label>
                        <input
                          type="radio"
                          id="star4"
                          name="rating"
                          value="4"
                        />
                        <label
                          className={
                            productDetail.rating_reviews.rating >= 4
                              ? "full custom_checked"
                              : "full"
                          }
                          htmlFor="star4"
                          title="4 stars"
                        ></label>
                        <input
                          type="radio"
                          id="star3"
                          name="rating"
                          value="3"
                        />
                        <label
                          className={
                            productDetail.rating_reviews.rating >= 3
                              ? "full custom_checked"
                              : "full"
                          }
                          htmlFor="star3"
                          title="3 stars"
                        ></label>
                        <input
                          type="radio"
                          id="star2"
                          name="rating"
                          value="2"
                        />
                        <label
                          className={
                            productDetail.rating_reviews.rating >= 2
                              ? "full custom_checked"
                              : "full"
                          }
                          htmlFor="star2"
                          title="2 stars"
                        ></label>
                        <input
                          type="radio"
                          id="star1"
                          name="rating"
                          value="1"
                        />{" "}
                        <label
                          className={
                            productDetail.rating_reviews.rating >= 1
                              ? "full custom_checked"
                              : "full"
                          }
                          htmlFor="star1"
                          title="1 star"
                        ></label>
                      </fieldset>
                    )}
                  </a>
                </div>

                <div
                  className={
                    appId == "14ECCD92-6352-4ED2-B242-8B4C005F7991"
                      ? "product-info-div multibuttons"
                      : "product-info-div"
                  }
                >
                  <div className="product-title-maindiv">
                    <div className="product-title">
                      <h3>
                        {productDetail.product_alias !== ""
                          ? stripslashes(productDetail.product_alias)
                          : stripslashes(productDetail.product_name)}
                      </h3>
                    </div>
                    <div className="product-short-description">
                      <div className="product-item-img">
                        {productDetail.product_tag.length > 0 && (
                          <>
                            {productDetail.product_tag.map(
                              (producttag, index1) => {
                                return (
                                  <img
                                    src={
                                      tagimagesource +
                                      "/" +
                                      producttag.pro_tag_image
                                    }
                                  />
                                );
                              }
                            )}
                          </>
                        )}
                      </div>

                      {productDetail.product_short_description != "" &&
                      productDetail.product_short_description != null
                        ? Parser(
                            stripslashes(
                              shorten(productDetail.product_short_description)
                            )
                          )
                        : Parser("")}
                    </div>
                  </div>
                  <div className="products-ordernow-action">
                    <div className="product-price">
                      {productDetail.product_specialprice_applicable ===
                        "yes" &&
                        (parseFloat(productDetail.product_price) > 0 ? (
                          <span className="price_disc">
                            {showPriceValue(productDetail.product_price)}
                          </span>
                        ) : (
                          parseFloat(productDetail.product_cost) > 0 && (
                            <span className="price_disc">
                              {showPriceValue(productDetail.product_cost)}
                            </span>
                          )
                        ))}
                      <h3>
                        {productDetail.product_specialprice_applicable === "yes"
                          ? showPriceValue(
                              productDetail.product_special_price,
                              "Y"
                            )
                          : showPriceValue(productDetail.product_price)}
                      </h3>
                    </div>

                    {/* <div className="product-price">
                    {productDetail.product_cost > 0 && (
                      <h4 className="original-price">
                        {showPriceValue(productDetail.product_cost)}
                      </h4>
                    )}
                    <h3>{showPriceValue(productDetail.product_price)}</h3>
                  </div> */}

                    {productDetail.pro_current_datetime_avl === "yes" &&
                    (productDetail.product_stock > 0 ||
                      productDetail.product_stock === null) ? (
                      productDetail.product_type === "1" ? (
                        appId == "14ECCD92-6352-4ED2-B242-8B4C005F7991" ? (
                          <div className="buttonscart">
                            <a
                              className="button order_nowdiv smiple_product_lk"
                              onClick={this.openProductUrl.bind(
                                this,
                                pro_cate_slug,
                                pro_subcate_slug,
                                productDetail.product_slug
                              )}
                            >
                              Order Now
                            </a>
                            <a
                              className="button order_nowdiv smiple_product_lk"
                              href="/"
                              onClick={this.addToCartSimple.bind(
                                this,
                                productDetail,
                                "initial"
                              )}
                              title={cart_label}
                              id={comboProId}
                            >
                              Add <span>to cart</span>
                            </a>
                          </div>
                        ) : (
                          <a
                            onClick={this.openProductUrl.bind(
                              this,
                              pro_cate_slug,
                              pro_subcate_slug,
                              productDetail.product_slug
                            )}
                            title={cart_label}
                            id={comboProId}
                            className="button order_nowdiv compo_product_lk"
                          >
                            Add <span>to cart</span>
                          </a>
                        )
                      ) : productDetail.product_type === "5" ? (
                        <a
                          className={
                            appId == "14ECCD92-6352-4ED2-B242-8B4C005F7991"
                              ? "button order_nowdiv smiple_product_lk disbl_href_action"
                              : "button order_nowdiv smiple_product_lk"
                          }
                          href="/"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "initial"
                          )}
                          title={cart_label}
                          id={comboProId}
                        >
                          Add <span>to cart</span>
                        </a>
                      ) : appId == "14ECCD92-6352-4ED2-B242-8B4C005F7991" ? (
                        <a
                          className="button order_nowdiv smiple_product_lk disbl_href_action"
                          href="/"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "initial"
                          )}
                          title={cart_label}
                          id={comboProId}
                        >
                          Add <span>to cart</span>
                        </a>
                      ) : (
                        <a
                          onClick={this.openProductUrl.bind(
                            this,
                            pro_cate_slug,
                            pro_subcate_slug,
                            productDetail.product_slug
                          )}
                          title={cart_label}
                          id={comboProId}
                          className="button order_nowdiv compo_product_lk"
                        >
                          Add <span>to cart</span>
                        </a>
                      )
                    ) : (
                      <a
                        className="button order_nowdiv disabled disbl_href_action"
                        href="/"
                      >
                        Sold Out
                      </a>
                    )}
                    <div className="addcart_row addcart_done_maindiv">
                      <div className="addcart-row-child">
                        <div className="qty_bx">
                          <span
                            className="qty_minus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "decr"
                            )}
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="proqty_input"
                            readOnly
                            value="1"
                          />
                          <span
                            className="qty_plus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "incr"
                            )}
                          >
                            +
                          </span>
                        </div>
                        <button
                          className="btn btn_black"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "done"
                          )}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.viewProductSlug === productDetail.product_slug &&
                this.state.productDetailsHtml}
            </div>
          </li>
        );
      }
    });
    if (totalProduct === 0) {
      $("#" + pro_cate_slug).hide();
    } else {
      $("#" + pro_cate_slug).show();
    }
    return listProduct;
  }

  showProductTags(tags) {
    return tags.map((item, index) => (
      <li key={index}>
        {item.pro_tag_image !== "" && (
          <img src={tagImageUrl + item.pro_tag_image} alt="tag" />
        )}
        {stripslashes(item.pro_tag_name)}
      </li>
    ));
  }

  checkExistCart(productID) {
    if (this.props.productState.cartList.length > 0) {
      var proExist = 0;
      this.props.productState.cartList.map((item) => {
        if (item.cart_item_product_id === productID) {
          proExist++;
        }
      });
      if (proExist > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  addFavourite(productID, fav_flag) {
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    if (customerId !== "") {
      var formData = {
        app_id: appId,
        product_id: productID,
        fav_flag: fav_flag,
        customer_id: cookie.load("UserId"),
      };
      var favproductID = this.state.favproductID;
      var favdeleteproductID = this.state.favdeleteproductID;
      if (fav_flag === "Yes") {
        favproductID.push(productID);
        const index1 = favdeleteproductID.indexOf(productID);
        if (index1 > -1) {
          favdeleteproductID.splice(index1, 1);
        }
      } else {
        const index = favproductID.indexOf(productID);
        if (index > -1) {
          favproductID.splice(index, 1);
        }
        favdeleteproductID.push(productID);
      }
      this.setState(
        { favproductID: favproductID, favdeleteproductID: favdeleteproductID },
        function () {
          this.productsubcatlist(
            this.state.OldupdateproductList,
            this.state.oldnavigateMenu
          );
        }
      );

      var postObject = qs.stringify(formData);
      this.props.getFavouriteproducts(postObject);
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }
  checkSeach(productName, tagList, brandTagList) {
    var searchKeyWord = this.state.searchKeyWord;
    var filter_Tag = this.state.filter_Tag;
    var filter_Brand_Tag = this.state.filter_Brand_Tag;
    var returnPro = 0;
    if (searchKeyWord !== "") {
      var proExistN = 0;
      if (productName.toLowerCase().indexOf(searchKeyWord.toLowerCase()) >= 0) {
        proExistN = 1;
      }
      if (proExistN == 0) {
        if (tagList !== "" && tagList !== null && tagList.length > 0) {
          tagList.map((item) => {
            if (
              item.pro_tag_name
                .toLowerCase()
                .indexOf(searchKeyWord.toLowerCase()) >= 0
            ) {
              proExistN++;
            }
          });
        }
      }
      if (proExistN == 1) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    } else {
      returnPro = 1;
    }

    if (returnPro === 1 && filter_Tag.length > 0) {
      let tagAvil = 0;

      tagList.map(function (item) {
        if (filter_Tag.indexOf(item.tag_id) >= 0) {
          tagAvil++;
        }
      });
      if (tagAvil > 0) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    }
    if (returnPro === 1 && filter_Brand_Tag.length > 0) {
      let tagAvil = 0;
      brandTagList.map(function (item) {
        if (filter_Brand_Tag.indexOf(item.pro_brand_tag_id) >= 0) {
          tagAvil++;
        }
      });
      if (tagAvil > 0) {
        returnPro = 1;
      } else {
        returnPro = 0;
      }
    }
    return returnPro;
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);

    if (actionFlg === "decr" && proqtyInput == 1) {
      $("#proIndex-" + indxFlg)
        .find(".smiple_product_lk")
        .show();
      $("#proIndex-" + indxFlg)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + indxFlg)
        .find(".product-list-main-div")
        .removeClass("add-cart");
    }

    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
      return;
    }

    if (productDetail.product_type === "5") {
      var IndexFlg = productDetail.product_primary_id;

      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });
      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });
    } else {
      /*  if (
        cookie.load("orderDateTime") === "" ||
        typeof cookie.load("orderDateTime") === undefined ||
        typeof cookie.load("orderDateTime") === "undefined"
      ) {
        $.magnificPopup.open({
          items: {
            src: "#takeaway-popup",
          },
          type: "inline",
        });
        return false;
      } */

      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        $("#proIndex-" + IndexFlg)
          .find(".product-list-main-div")
          .addClass("add-cart");
        return false;
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var userAccountType =
          typeof cookie.load("userAccountType") === "undefined"
            ? ""
            : cookie.load("userAccountType");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;
        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          login_type: userAccountType,
          reference_new_id: getReferenceID(),
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          $("#proIndex-" + IndexFlg)
            .find(".product-list-main-div")
            .removeClass("add-cart");
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();

            if (appId !== "BC16ED35-AB91-437B-8C15-AFEE54359E06") {
              this.handleShowAlertFun(
                "success",
                "Great choice! Item added to your cart."
              );
            }

            if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
              createScriptForAddToCart();
            }
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
          }
          return false;
        });
      }
    }
  }

  addToCartVoucherMe() {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/

    if (!cookie.load("UserId")) {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
      cookie.save("redirectProducts", "Yes", { path: "/" });
      return false;
    }

    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");
    var userAccountType =
      typeof cookie.load("userAccountType") === "undefined"
        ? ""
        : cookie.load("userAccountType");
    var referenceNewId = getReferenceID();

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;
    var postObject = {};

    var ImagePath = this.state.voucherProductDetail.product_thumbnail;
    if (ImagePath !== "") {
      var postImagePath =
        this.props.productCommon.product_image_source +
        "/" +
        this.state.voucherProductDetail.product_thumbnail;
    } else {
      var postImagePath = "";
    }

    postObject = {
      app_id: appId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_type: 5,
      product_image: postImagePath,
      availability_id: availabilityId,
      availability_name: availabilityName,
      isAddonProduct: isAddonProduct,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      login_type: userAccountType,
      reference_new_id: referenceNewId,
      voucher_for: "Me",
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    showLoader("add_to_voucher", "class");

    Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      hideLoader("add_to_voucher", "class");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".product-list-main-div")
        .removeClass("add-cart");
      if (res.data.status === "ok") {
        this.props.sateValChange("cartflg", "yes");
        removePromoCkValue();
        showCustomAlert("success", "Great choice! Item added to your cart.");
        /*showCartLst();*/
        if (appId !== "BC16ED35-AB91-437B-8C15-AFEE54359E06") {
          this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          );
        }
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This is not a valid email.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var userAccountType =
        typeof cookie.load("userAccountType") === "undefined"
          ? ""
          : cookie.load("userAccountType");
      var referenceNewId = getReferenceID();

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.props.productCommon.product_image_source +
          "/" +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        var postImagePath = "";
      }

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_image: postImagePath,
        product_type: 5,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        login_type: userAccountType,
        reference_new_id: referenceNewId,
        voucher_for: "Gift",
        voucher_gift_name: this.state.voucher_name,
        voucher_gift_mobile: this.state.voucher_mobile,
        voucher_gift_email: this.state.voucher_email,
        voucher_gift_message: this.state.voucher_message,
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };

      Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
        (res) => {
          $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
          hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".smiple_product_lk")
            .show();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".product-list-main-div")
            .removeClass("add-cart");
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            if (appId !== "BC16ED35-AB91-437B-8C15-AFEE54359E06") {
              this.handleShowAlertFun(
                "success",
                "Great choice! Item added to your cart."
              );
            }
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucher_name: "",
              voucher_mobile: "",
              voucher_email: "",
              voucher_message: "",
            });
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucherIndexFlag: "",
            });
          }

          return false;
        }
      );
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    this.setState({ ["error_" + item]: "" });
  };

  addClassInput() {
    $(".input-focus").focus(function () {
      $(this).parents(".focus-out").addClass("focused");
    });
    $(".input-focus").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this).parents(".focus-out").removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.props.sateValChange("cartflg", value);
    }
    if (field === "closeprodetails" && value === "yes") {
      this.setState(
        { productDetailsHtml: "", viewProductSlug: "", proclose: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (field === "changenavslug" && value !== "") {
      var changeSlug = value.split("_");
      this.setState({ scrollProduct: changeSlug[1] });
      this.props.history.push("/menu/category/" + changeSlug[0]);
    }
  };

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }
  sortByPrice(sort, event) {
    if (
      event !== "" &&
      typeof event !== undefined &&
      typeof event !== "undefined"
    ) {
      event.preventDefault();
    }

    var sort_By_PriceName = "";
    if (sort === "ASC") {
      sort_By_PriceName = "Price Low to High";
    } else {
      sort_By_PriceName = "Price High to Low";
    }
    this.setState({
      sort_By_Price: sort,
      sort_By_PriceName: sort_By_PriceName,
    });
    showLoader("innerproduct_row", "class");
    this.props.getProductList(
      this.state.catSlug,
      this.state.subcatSlug,
      "",
      "product_price",
      sort
    );
  }
  sortByCategories(categorySlug, categoryName, event) {
    event.preventDefault();

    var link = "/menu/category/" + categorySlug;
    this.props.history.push(link);

    showLoader("productlist-main-div", "class");
    this.setState(
      {
        sort_By_category: categorySlug,
        sort_By_categoryName: categoryName,
      },
      function () {
        this.props.sateValChange("categorySlug", categorySlug);
      }
    );
    var sortKey = "";
    var sortValue = "";
    if (this.state.sort_By_Price !== "") {
      sortKey = "product_price";
      sortValue = this.state.sort_By_Price;
    }
    showLoader("innerproduct_row", "class");
    this.props.getProductList(categorySlug, "", "", sortKey, sortValue);
  }

  showSortBy(type) {
    if (type === "price") {
      if (this.state.showShortByPrice === true) {
        this.setState({ showShortByPrice: false });
      } else {
        this.setState({ showShortByPrice: true, showShortByCategory: false });
      }
    } else if (type === "category") {
      if (this.state.showShortByCategory === true) {
        this.setState({ showShortByCategory: false });
      } else {
        this.setState({ showShortByCategory: true, showShortByPrice: false });
      }
    }
  }
  searchProKeyPress = (event) => {
    this.setState({ searchKeyWord: event.target.value }, function () {
      this.productsubcatlist();
    });
    if (event.target.value === "") {
      this.setState({ emptysearchKey: 1 });
    }
  };

  render() {
    return (
      <div className="productlist-main-div">
        <div className="mobile-pantry-search">
          <div className="input-sec">
            <input
              type="text"
              name="searchkey"
              className="productsearch"
              placeholder="Search..."
              onKeyUp={this.searchProKeyPress}
            />
            <a
              href="/"
              id="clearSearch"
              className="search_text_clear disbl_href_action"
            >
              X
            </a>
          </div>
        </div>
        <div className="product-filters">
          <div className="filters-lhs">
            <span>Sort By Price</span>
            <span
              className="filter-act"
              onClick={this.showSortBy.bind(this, "price")}
            >
              {this.state.sort_By_PriceName}
            </span>
            <ul
              className={this.state.showShortByPrice === true ? "active" : ""}
            >
              <li
                className={this.state.sort_By_Price === "ASC" ? "active" : ""}
              >
                <a href="#" onClick={this.sortByPrice.bind(this, "ASC")}>
                  Price Low to High
                </a>
              </li>
              <li
                className={this.state.sort_By_Price === "DESC" ? "active" : ""}
              >
                <a href="#" onClick={this.sortByPrice.bind(this, "DESC")}>
                  Price High to Low
                </a>
              </li>
            </ul>
          </div>

          <div className="filters-rhs">
            <span>Shop By Category</span>
            <span
              className="filter-act"
              onClick={this.showSortBy.bind(this, "category")}
            >
              {this.state.sort_By_categoryName}
            </span>
            {this.props.navigateMenu.length > 0 && (
              <ul
                className={
                  this.state.showShortByCategory === true ? "active" : ""
                }
              >
                {this.props.navigateMenu.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={
                        this.state.sort_By_category === item.pro_cate_slug
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href="#"
                        onClick={this.sortByCategories.bind(
                          this,
                          item.pro_cate_slug,
                          item.menu_custom_title
                        )}
                      >
                        {item.menu_custom_title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        <div className="innerproduct_row flex-ipad">
          <div className="ipad-products">
            <MenuNavigationTab {...this.props} productState={this.state} />
          </div>
          {this.state.productDetails}
        </div>
        <div
          className="no-product"
          id="no_product_error"
          style={{ display: "none" }}
        >
          <div className="container">
            <h2>No product found</h2>
            <p>
              Sorry, we can't find the type of products you are looking for. Try
              widening your search to see what’s available.
            </p>
          </div>
        </div>

        <div
          id="vouchergift-popup"
          className="white-popup mfp-hide popup_sec vouchergift-popup"
        >
          <div className="voucher-popup-inner pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>IM PURCHASING THIS</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body product_voucher_body">
                <div className="mainacc_menucontent">
                  <div className="main_tabsec">
                    <div className="order-tab-section">
                      <div className="mainacc_mobrow">
                        <div className="tab_sec main_tabsec_inner product_tag_sec_main">
                          <div className="myacc_filter">
                            <div className="tab_sec filter_tabsec">
                              <ul className="nav nav-tabs text-center product_voucher">
                                <li className="active">
                                  <a
                                    data-toggle="tab"
                                    href="#tab-id-inn5"
                                    aria-expanded="true"
                                  >
                                    <span>For Gift</span>
                                  </a>
                                </li>
                                <li className="">
                                  <a
                                    data-toggle="tab"
                                    href="#tab-id-inn6"
                                    aria-expanded="false"
                                  >
                                    <span>For Me</span>
                                  </a>
                                </li>
                              </ul>
                              <div className="tab-content">
                                <div
                                  id="tab-id-inn5"
                                  className="tab-pane fade active in"
                                >
                                  <h4 className="tab_mobtrigger inner_tab_border active">
                                    <i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="order-delivery">
                                      <div className="ord-body">
                                        <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                                          <ul className="myacc_order_details">
                                            <div className="voucher-popup-inner product-voucher-popup">
                                              <h2>
                                                GIFT RECEIVER'S INFORMATION
                                              </h2>
                                              <div className="form-group">
                                                <div className="focus-out">
                                                  <label>Name</label>
                                                  <input
                                                    type="text"
                                                    id="guest-name"
                                                    name="guest-name"
                                                    value={
                                                      this.state.voucher_name
                                                    }
                                                    onClick={this.addClassInput.bind(
                                                      this
                                                    )}
                                                    onChange={this.handleChangeTxt.bind(
                                                      this,
                                                      "voucher_name"
                                                    )}
                                                    className="form-control input-focus"
                                                  />
                                                  {
                                                    this.state
                                                      .error_voucher_name
                                                  }
                                                </div>
                                              </div>
                                              <div className="form-group">
                                                <div className="focus-out">
                                                  <label>
                                                    Enter Mobile Number
                                                  </label>
                                                  <input
                                                    type="tel"
                                                    id="guest-number"
                                                    name="guest-number"
                                                    value={
                                                      this.state.voucher_mobile
                                                    }
                                                    onClick={this.addClassInput.bind(
                                                      this
                                                    )}
                                                    onChange={this.handleChangeTxt.bind(
                                                      this,
                                                      "voucher_mobile"
                                                    )}
                                                    className="form-control input-focus"
                                                  />
                                                  {
                                                    this.state
                                                      .error_voucher_mobile
                                                  }
                                                </div>
                                              </div>
                                              <div className="form-group">
                                                <div className="focus-out">
                                                  <label>Email Address</label>
                                                  <input
                                                    type="text"
                                                    id="guest-email"
                                                    name="guest-email"
                                                    value={
                                                      this.state.voucher_email
                                                    }
                                                    onClick={this.addClassInput.bind(
                                                      this
                                                    )}
                                                    onChange={this.handleChangeTxt.bind(
                                                      this,
                                                      "voucher_email"
                                                    )}
                                                    className="form-control input-focus"
                                                  />
                                                  {
                                                    this.state
                                                      .error_voucher_email
                                                  }
                                                </div>
                                              </div>
                                              <div className="form-group">
                                                <div className="focus-out">
                                                  <label>Message</label>
                                                  <textarea
                                                    type="text"
                                                    id="guest-message"
                                                    name="guest-message"
                                                    value={
                                                      this.state.voucher_message
                                                    }
                                                    onClick={this.addClassInput.bind(
                                                      this
                                                    )}
                                                    onChange={this.handleChangeTxt.bind(
                                                      this,
                                                      "voucher_message"
                                                    )}
                                                    className="form-control input-focus"
                                                  />
                                                  {
                                                    this.state
                                                      .error_voucher_message
                                                  }
                                                </div>
                                              </div>

                                              <div className="howtouse-gift-vouchar">
                                                <img src={smartPhone} />
                                                <h3>How to use</h3>
                                                <p>
                                                  After purchasing, your
                                                  recipient will get email
                                                  notification to use the above
                                                  email address to sign up.
                                                  Vouchers will be available
                                                  within their account.
                                                </p>
                                              </div>
                                              <div className="form-group gift-group">
                                                <button
                                                  type="button"
                                                  className="button add_to_voucher"
                                                  onClick={this.addToCartVoucher.bind(
                                                    this
                                                  )}
                                                >
                                                  Add <span>to cart</span>
                                                </button>
                                              </div>
                                            </div>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div id="tab-id-inn6" className="tab-pane fade">
                                  <h4 className="tab_mobtrigger inner_tab_border">
                                    <i></i>
                                  </h4>
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                                      <ul className="myacc_order_details used-voucher">
                                        <div className="voucher-popup-inner product-voucher-popup">
                                          <div className="howtouse-gift-vouchar">
                                            <img src={smartPhone} />
                                            <h3>How to use</h3>
                                            <p>
                                              After purchasing, your recipient
                                              will get email notification to use
                                              the above email address to sign
                                              up. Vouchers will be available
                                              within their account.
                                            </p>
                                          </div>

                                          <button
                                            type="button"
                                            className="button add_to_voucher"
                                            onClick={this.addToCartVoucherMe.bind(
                                              this
                                            )}
                                          >
                                            Add <span>to cart</span>
                                          </button>
                                        </div>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="product-load-more">
          <a href="#" className="">
            <span>LOAD</span> MORE
          </a>
        </div> */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (
      catslug,
      subcatslug,
      outletid,
      sortByField,
      sortByValue
    ) => {
      dispatch({
        type: GET_PRODUCT_LIST,
        catslug,
        subcatslug,
        outletid,
        sortByField,
        sortByValue,
      });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
